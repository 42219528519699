* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/public/assets/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow: hidden;
}

h2 {
  font-size: 20px;
}

.page {
  height: 100vh;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.home {
  max-width: 90vw;
  max-height: 90vh;
  padding: 40px;
  border-radius: 40px;
  background: rgba(18, 18, 18, 0.9);
}

.home .header {
  text-transform: uppercase;
  font-family: 'DM Serif Text';
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4em;
  text-align: center;
  color: #FFFFFF;
}

.home .header h2 {
  white-space: nowrap;
}

.home .games {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  overflow-y: auto;
  scrollbar-width: none;
}

.home .games img {
  width: 100%;
  max-height: 100%;
}

.back-header {
  padding: 24px 32px;
  background: #191919;
}

.back-header img {
  width: 32px;
  height: 32px;
  padding: 8px;
}

.game-page iframe {
  flex: 1;
  outline: none;
  border: none;
  background: #191919;
}

@media (max-width: 600px) {
  h2 {
    font-size: 14px;
  }

  .home {
    width: 95vw;
    max-height: 95vh;
  }

  .home .games {
    grid-template-columns: repeat(2, 1fr);
  }

  .back-header {
    padding: 18px 10px;
  }
}